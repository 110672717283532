<template>
    <div>
        <blogs-component/>
        <footer-component/>
    </div>
    
</template>

<script>
    import BlogsComponent from "@/components/blogs/BlogsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Blogs",
        title: "Blogs de Noticias | Turismo BC",
        components: { BlogsComponent, FooterComponent },
    }
</script>

<style scoped>

</style>